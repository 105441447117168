import {AbstractFollowMeEntity, FollowMeEntityInterface} from '../entity';
import {Organization, OrganizationInterface} from '../organizations/organizations.models';


export interface InfoInterface extends FollowMeEntityInterface {
  title: string;
  content: string;
  createdAt: Date;
  organization?: OrganizationInterface;
  organizationIri: string;
}

export class Info extends AbstractFollowMeEntity implements InfoInterface {
  public title: string = null;
  public content: string = null;
  public createdAt: Date = null;
  public organizationIri: string = null;
  public organization?: Organization = null;
  public variant?: 'cancel' | 'danger' | 'success' | 'primary' | 'coming_soon' = 'danger';
  public status?: string = 'Infos';
  public icon?: string = './assets/icon/info.svg';


  public static override getEntityName(): 'info' {
    return 'info';
  }
}
