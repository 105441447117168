/* eslint-disable @typescript-eslint/no-explicit-any */
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {isDevMode} from '@angular/core';
import {HANDLER_RELATED_ENTITY} from '@followme/ngrx-entity-relationship';
import {
  ApiClient,
  buildOne,
  EntityConstructorInterface,
  PagedCollection,
  PartiallySerialized,
  urlJoin,
} from '@forlabs/api-bridge';
import {SelectorServiceInterface} from '@forlabs/api-bridge/lib/abstract-entity/selectors';
import {
  DataServiceError,
  DefaultDataService,
  DefaultDataServiceConfig,
  EntityCollection,
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory,
  EntityMetadataMap,
  EntityOp,
  HttpUrlGenerator,
  OP_ERROR,
  OP_SUCCESS,
  PersistanceCanceled,
  RequestData,
} from '@ngrx/data';
import {EntityAction, EntityActionOptions} from '@ngrx/data/src/actions/entity-action';
import {HttpMethods} from '@ngrx/data/src/dataservices/interfaces';
import {Update} from '@ngrx/entity';
import {Action, Store} from '@ngrx/store';
import {HANDLER_ROOT_ENTITY, rootEntities, rootEntitySelector, toStaticSelector} from 'ngrx-entity-relationship';
import {catchError, delay, map, mergeMap, Observable, of, shareReplay, take, throwError, timeout} from 'rxjs';
import {filter} from 'rxjs/operators';
import {metaActionSecureAction} from './_meta/actions';
import {Article} from './articles/articles.models';
import {CourseMessage} from './course-messages/course-messages.models';
import {FollowMeEntityInterface, selectId} from './entity';
import {Info} from './infos/infos.models';
import {Organization} from './organizations/organizations.models';
import {PatientStep} from './patient-steps/patient-steps.models';
import {Contact, HealthPro, Patient, User} from './users/users.models';
import {WorkflowButton} from './workflow-buttons/workflow-buttons.models';
import {Workflow, WorkflowInterface} from './workflows/workflows.models';


export const entityMetadata: EntityMetadataMap = {
  [Article.getEntityName()]: {selectId},
  [CourseMessage.getEntityName()]: {selectId},
  [Info.getEntityName()]: {selectId},
  [Organization.getEntityName()]: {selectId},
  [PatientStep.getEntityName()]: {selectId},
  [User.getEntityName()]: {selectId},
  [Contact.getEntityName()]: {selectId},
  [HealthPro.getEntityName()]: {selectId},
  [Patient.getEntityName()]: {selectId},
  [WorkflowButton.getEntityName()]: {selectId: (workflow: WorkflowInterface): string => workflow.name},
  [Workflow.getEntityName()]: {selectId: (workflow: WorkflowInterface): string => workflow.name},
};

export const pluralNames = {
  // Case matters. Match the case of the entity name.
  // Hero: 'Heroes'
};

export const defaultDataServiceConfig: DefaultDataServiceConfig = {
  timeout: 10000, // request timeout
  trailingSlashEndpoints: false,
};

interface HttpOptions {
  httpParams?: {
    fromString?: string;
    fromObject?: {
      [param: string]:
      | string
      | number
      | boolean
      | ReadonlyArray<string | number | boolean>;
    };
  };
  httpHeaders?: string | { [p: string]: string | string[] };
}

export class FollowMeDataService<Entity extends FollowMeEntityInterface> extends DefaultDataService<Entity> {
  protected override timeout = 0;

  constructor(
    protected entityConstructor: EntityConstructorInterface<Entity>,
    protected apiClient: ApiClient,
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    config?: DefaultDataServiceConfig,
  ) {
    super(entityConstructor.getEntityName(), http, httpUrlGenerator, {
      ...config,
      root: urlJoin(apiClient.getApiUrl(), '/api/'),
    });

    // FIXME: This probably breaks some behavior that is usually triggered by
    //  https://github.com/ngrx/platform/blob/master/modules/data/src/dataservices/http-url-generator.ts#L82
    //  This whole service should probably be rewritten from scratch instead of trying to extend DefaultDataService?
    this.entityUrl = this.entityConstructor.getBaseUri();
    this.entitiesUrl = this.entityConstructor.getBaseUri();
  }

  public reloadAll(): Observable<Entity[]> {
    // Utilisez directement l'API pour obtenir les données
    return this.apiClient.get<PagedCollection<Entity>>(this.entityConstructor.getBaseUri(), {params: new HttpParams()}).pipe(
      map(jsonData => jsonData['hydra:member']), // Ajustez en fonction de votre structure de données
    );
  }

  public override getAll(options?: HttpOptions): Observable<Entity[]> {
    return this.execute('GET_COLLECTION', null, null, options);
  }

  public override getById(key: number | string, options?: HttpOptions): Observable<Entity> {
    console.log('get by id', key);
    let err: Error;
    if (key == null) {
      err = new Error(`No "${this.entityName}" key to get`);
    }
    return this.execute('GET', '' + key, err, options);
  }

  public override add(entity: PartiallySerialized<Entity>, options?: HttpOptions): Observable<Entity> {
    const entityOrError = entity as Record<string, unknown> || new Error(`No "${this.entityName}" entity to add`);
    return this.execute('POST', this.entityConstructor.getBaseUri(), entityOrError, options);
  }

  public override delete(
    key: number | string,
    options?: HttpOptions,
  ): Observable<string> {
    let err: Error | undefined;
    if (key == null) {
      err = new Error(`No "${this.entityName}" key to delete`);
    }

    return this.execute(
      'DELETE',
      key as string,
      err,
      null,
      options,
    ).pipe(
      // forward the id of deleted entity as the result of the HTTP DELETE
      map(() => key as string),
    );
  }

  public override update(update: Update<Entity>, _options?: HttpOptions): Observable<Entity> {
    const id = update && update.id;
    const updateOrError =
      id == null
        ? new Error(`No "${this.entityName}" update data or id`)
        : update.changes;
    return this.execute(
      'PUT',
      id as string,
      updateOrError,
      undefined,
      {httpHeaders: {'Content-Type': 'application/merge-patch+json'}},
    );
  }

  protected override execute(
    method: HttpMethods | 'GET_COLLECTION',
    url: string,
    data?: Error | Record<string, unknown>, // data, error, or undefined/null
    options?: unknown, // options or undefined/null
    httpOptions?: HttpOptions, // these override any options passed via options
  ): Observable<any> {
    let entityActionHttpClientOptions: any = undefined;
    if (httpOptions) {
      entityActionHttpClientOptions = {
        headers: httpOptions?.httpHeaders
          ? new HttpHeaders(httpOptions?.httpHeaders)
          : undefined,
        params: httpOptions?.httpParams
          ? new HttpParams(httpOptions?.httpParams)
          : undefined,
      };
    }

    // Now we may have:
    // options: containing headers, params, or any other allowed http options already in angular's api
    // entityActionHttpClientOptions: headers and params in angular's api

    // We therefore need to merge these so that the action ones override the
    // existing keys where applicable.

    // If any options have been specified, pass them to http client. Note
    // the new http options, if specified, will override any options passed
    // from the deprecated options parameter
    let mergedOptions: any = undefined;
    if (options || entityActionHttpClientOptions) {
      if (isDevMode() && options && entityActionHttpClientOptions) {
        console.warn(
          // eslint-disable-next-line max-len
          '@ngrx/data: options.httpParams will be merged with queryParams when both are are provided to getWithQuery(). In the event of a conflict HttpOptions.httpParams will override queryParams`. The queryParams parameter of getWithQuery() will be removed in next major release.',
        );
      }

      mergedOptions = {
        ...(options as any),
        headers: entityActionHttpClientOptions?.headers ?? (options as any)?.headers,
        params: entityActionHttpClientOptions?.params ?? (options as any)?.params,
      };
    }

    const req: RequestData = {
      method: method === 'GET_COLLECTION' ? 'GET' : method,
      url,
      data,
      options: mergedOptions,
    };

    if (data instanceof Error) {
      return this.handleError2(req)(data);
    }

    let result$: Observable<any>;

    switch (method) {
      case 'DELETE': {
        result$ = this.apiClient.delete(url, mergedOptions);
        if (this.saveDelay) {
          result$ = result$.pipe(delay(this.saveDelay));
        }
        break;
      }
      case 'GET_COLLECTION': {
        result$ = this.apiClient.get<PagedCollection<Entity>>(this.entityConstructor.getBaseUri(), mergedOptions).pipe(
          map(jsonData => jsonData['hydra:member']),
        );
        if (this.getDelay) {
          result$ = result$.pipe(delay(this.getDelay));
        }
        break;
      }
      case 'GET': {
        result$ = this.apiClient.get(url, mergedOptions);
        if (this.getDelay) {
          result$ = result$.pipe(delay(this.getDelay));
        }
        break;
      }
      case 'POST': {
        result$ = this.apiClient.post(url, data, mergedOptions);
        if (this.saveDelay) {
          result$ = result$.pipe(delay(this.saveDelay));
        }
        break;
      }
      // N.B.: It must return an Update<T>
      case 'PUT': {
        result$ = this.apiClient.patch(url, data, mergedOptions);
        if (this.saveDelay) {
          result$ = result$.pipe(delay(this.saveDelay));
        }
        break;
      }
      default: {
        const error = new Error('Unimplemented HTTP method, ' + method);
        result$ = throwError(() => error);
      }
    }
    if (this.timeout) {
      result$ = result$.pipe(timeout({
        first: this.timeout + this.saveDelay,
      }));
    }
    return result$.pipe(catchError(this.handleError2(req)));
  }

  private handleError2(reqData: RequestData) {
    return (err: any) => {
      const ok = this.handleDelete4042(err, reqData);
      if (ok) {
        return ok;
      }
      const error = new DataServiceError(err, reqData);
      return throwError(error);
    };
  }

  private handleDelete4042(error: HttpErrorResponse, reqData: RequestData) {
    if (
      error.status === 404 &&
      reqData.method === 'DELETE' &&
      this.delete404OK
    ) {
      return of({});
    }
    return undefined;
  }
}

export class EntityCollectionService<Entity extends FollowMeEntityInterface> extends EntityCollectionServiceBase<Entity> {
  // If needed, override this to use a different id than 'id'
  // toUpdate: (entity: Partial<T>) => Update<T>;
  protected reducedActions$: Observable<Action>;

  constructor(
    protected entityConstructor: EntityConstructorInterface<Entity>,
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    scannedActions$: Observable<Action>,
  ) {
    super(entityConstructor.getEntityName(), serviceElementsFactory);

    this.reducedActions$ = scannedActions$.pipe(shareReplay(1));
  }

  // public createAndDispatchSensitiveAction<P = any>(op: EntityOp, data?: P, options?: EntityActionOptions): SecureAction<EntityAction<P>> {
  //   // TODO: Allow options for both the underlying option and for this one (like, allow this option to be optimistic?)
  //   const sensitiveActionWithoutToken = this.dispatcher.createEntityAction(op, data, options);
  //   const action = metaActionSecureAction({action: sensitiveActionWithoutToken});
  //   this.dispatcher.dispatch(action);
  //   return action;
  // }
  //
  // public createAndDispatchSensitiveAction<P = any>(op: EntityOp, data?: P, options?: EntityActionOptions): SecureAction<EntityAction<P>> {
  //
  //   this.dispatcher.getResponseData$<T>(options.correlationId).pipe(
  //     // Use the returned entity data's id to get the entity from the collection
  //     // as it might be different from the entity returned from the server.
  //     withLatestFrom(this.entityCollection$),
  //     map(([e, collection]) => collection.entities[this.selectId(e)]!),
  //     shareReplay(1)
  //   );
  //   return action;
  // }

  public secureDelete(
    password: string,
    arg: number | string | Entity,
    options?: EntityActionOptions,
  ): Observable<number | string> {
    const key = this.getKey(arg).toString();
    return this.createAndDispatchSensitiveAction<number | string | Entity>(
      password,
      key,
      EntityOp.SAVE_DELETE_ONE,
      arg,
      options,
    ).pipe(
      map(() => key),
      // shareReplay(1),
    );
  }

  public secureAdd(
    password: string,
    arg: PartiallySerialized<Entity>,
    options?: EntityActionOptions,
  ): Observable<Entity> {
    return this.createAndDispatchSensitiveAction<Entity>(
      password,
      this.entityConstructor.getBaseUri(),
      EntityOp.SAVE_ADD_ONE,
      arg as Entity,
      {
        ...options,
        isOptimistic: false,
      },
    ).pipe(
      // shareReplay(1),
    );
  }

  public secureUpdate(
    password: string,
    arg: PartiallySerialized<Entity>,
    options?: EntityActionOptions,
  ): Observable<number | string> {
    const key = this.getKey(arg as Entity).toString();
    return this.createAndDispatchSensitiveAction<Update<Entity>>(
      password,
      key,
      EntityOp.SAVE_UPDATE_ONE,
      this.dispatcher.toUpdate(arg as Entity),
      options,
    ).pipe(
      // shareReplay(1),
    );
  }

  private getKey(arg: number | string | Entity): string | number {
    return typeof arg === 'object'
      ? this.selectId(arg)
      : (arg as number | string);
  }

  private createAndDispatchSensitiveAction<P = any>(
    password: string,
    uri: string,
    op: EntityOp,
    data?: P,
    options?: EntityActionOptions,
  ): Observable<any> {
    // TODO: Allow options for both the underlying option and for this one (like, allow this option to be optimistic?)
    const sensitiveActionWithoutToken = this.dispatcher.createEntityAction(op, data, options);
    const methods: Partial<Record<EntityOp, HttpMethods | 'PATCH'>> = {
      [EntityOp.SAVE_DELETE_ONE]: 'DELETE',
      [EntityOp.SAVE_UPDATE_ONE]: 'PATCH',
    };
    const action = metaActionSecureAction({
      password,
      specs: {
        method: methods[op] ?? null,
        request_uri: uri,
      },
      action: sensitiveActionWithoutToken,
    });
    this.setLoading(true);
    this.dispatcher.dispatch(action);

    return this.reducedActions$.pipe(
      filter((act: any) => !!act.payload),
      filter((act: EntityAction) => {
        const {correlationId, entityName, entityOp} = act.payload;
        return (
          entityName === this.entityName &&
          correlationId === options.correlationId &&
          (entityOp.endsWith(OP_SUCCESS) ||
            entityOp.endsWith(OP_ERROR) ||
            entityOp === EntityOp.CANCEL_PERSIST)
        );
      }),
      take(1),
      mergeMap((act) => {
        const {entityOp} = act.payload;
        return entityOp === EntityOp.CANCEL_PERSIST
          ? throwError(new PersistanceCanceled(act.payload.data))
          : entityOp.endsWith(OP_SUCCESS)
            ? of(act.payload.data as P)
            : throwError(act.payload.data.error);
      }),
    );
  }

  public override add(entity: PartiallySerialized<Entity>, options: EntityActionOptions & {
    isOptimistic: false
  }): Observable<Entity> {
    return super.add(entity as Partial<Entity>, options);
  }

  public override update(entity: PartiallySerialized<Entity>, options?: EntityActionOptions): Observable<Entity> {
    return super.update(entity as Partial<Entity>, options);
  }

  // TODO: Deprecate all selectors in favor of using the ones from EntitySelectorService<Entity>
}

export class EntitySelectorService<EntityInterface extends FollowMeEntityInterface, Entity extends EntityInterface>
implements SelectorServiceInterface<EntityInterface, Entity> {
  public all$: Observable<Entity[]>;
  public selectAll: {(state: EntityCollection<EntityInterface>): Entity[], release(): void};

  private readonly rootEntitySelectorWithRelationships:
  HANDLER_ROOT_ENTITY<EntityCollection<EntityInterface>, EntityInterface, Entity, string | number>;

  constructor(
    protected store: Store,
    entityConstructor: EntityConstructorInterface<Entity>,
    private entityCollectionService: EntityCollectionService<EntityInterface>,
    relationships: Array<HANDLER_RELATED_ENTITY<EntityCollection<EntityInterface>, Entity>> = [],
  ) {
    const rootSelector = rootEntitySelector(
      entityCollectionService,
      entity => buildOne(entityConstructor, entity) as Entity,
    );
    this.rootEntitySelectorWithRelationships = rootSelector(...relationships);

    this.selectAll = toStaticSelector(
      rootEntities(this.rootEntitySelectorWithRelationships),
      this.entityCollectionService.selectors.selectKeys,
    ) as any;
    this.all$ = this.store.select(this.selectAll);
  }

  public selectOneByIri(iri: string): {(state): Entity, release: () => void} {
    return toStaticSelector(
      this.rootEntitySelectorWithRelationships,
      iri,
    );
  }

  public oneByIri$(iri: string): Observable<Entity> {
    // TODO: make sure this selector is cached
    return this.store.select(this.selectOneByIri(iri));
  }

  // public oneByCreationToken$(token: string): Observable<Entity> {
  //   return this.store.select(toStaticSelector(
  //     this.rootEntitySelectorWithRelationships,
  //     token,
  //   ));
  // }
}
